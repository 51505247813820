@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700;800&display=swap');

body {
  background-color: #FFFFFF;
  background-image: url("../images/bg_body.png");
  background-position: left 75px;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 110px;
  scroll-behavior: smooth;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  height:100%;
  min-height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {	
  width: 6px;	
  background-color: #3e8b8a;	
}	
::-webkit-scrollbar-thumb {	
  background-color: #f3b342;	
  border-radius: 10px;
}

.shuffle-disabled{
  pointer-events: none;
  opacity: 0.7;
}

#noMessage{
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#noMessage p{
  font-size: 46px;
  color: white;
}

#smooth-content{
  overflow: hidden;
}

.stop-scrolling {
  overflow: hidden;
}

.pushed{
  opacity: 0;
}

#smooth-content{
  overflow: hidden;
}

#main-wrapper{
  height: 100vh;
}


  .navbar {
    background-color: #FFFFFF;
    box-shadow: 20px 21px 130px 0 rgba(18,5,41,0.20), inset 1px 1px 2px 0 rgba(255,255,255,0.18);
  }
 /* .triangle-left {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 80px 40px 0 0;
    border-color: #ffffff transparent transparent transparent;
  }
  .triangle-right {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 80px 0;
    border-color: transparent #ffffff transparent transparent;
  }*/
  .logo {
    width: 22rem;
    max-width: 420px;
    min-width: 220px;
    height: 80px;
    padding: 0rem 1rem 1rem 3rem;
  }
  .logo img {
    width: 16rem;
    max-width: 240px;
    margin-top: 7px;
  }
  #add-button {
    position: absolute;
  }
  #btn-add {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: 3rem;
    color: white;
    background-color: #3e8b8a;
    box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.75);
    z-index: 10;
    width: 60px;
    height: 60px;
  }

  #wrapper{
    display:inline-block;
  }
  #a,#b{
    width:40px;
    height:50px;
    padding:5px;
    color: white;
    cursor:pointer;
    float: left;
    transition: all 0.8s cubic-bezier(0.65, 0, 0.076, 1);
    border-radius: 3rem;
  }
  #b{
    background-image: radial-gradient(circle at 14% -1.4e+02%, #193f4c 0%, #3e8b8a 431%);
    border-radius: 32.5px;
    bottom: 25px;
    right: 25px;
    position: fixed;
    color: transparent;
    clip-path: inset(0 -30% 0% 0);
    z-index: 9;
    overflow: hidden;
    white-space: nowrap;
  }
  #b span {
    width: 230px;
    margin-right: 40px;

  } 
  #a + #b{
    background-color: #00BCB5;
    width: 240px;
    color: white !important;
    padding: 0.8rem 1.5rem;
  }

.image {
  position: relative;
  border-radius: 16px;
  clip-path: inset(0 1px 1px 0);
}

.info {
  position:absolute;
  background-color:black;
  width:100%;
}

#photos {
  /* -moz-column-count: 2;
       column-count: 2;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  -moz-column-fill: balance;
       column-fill: balance;
  padding: 0 1rem; */
  width: 98%;
  margin: 0 auto;
  opacity: 0;
}

#photos img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
  border-radius: 16px;
 
}

#photos iframe{
  border-radius: 16px;
}

#photos button{
  color: white;
  background-color: #12607d;
  border-color: #12607d;
  width: 60%;
  font-size: 3vw;
  border-radius: 50px;
  font-family: "open Sans";
  font-weight: 700;
  position: absolute;
  bottom: 1rem;
  display: none;
}

/* LOADER BEGINS */

.no-content .loader{
  display: none;
}

.no-content .mouse{
  display: none !important;
}

.loader {
  position: fixed;
  z-index: 10;
  left: 50%;
  top: 50%;
  margin-left: -24px;
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: #f3b342;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation .7s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

/* LOADER ENDS */

.divHover {
    width: 100%;
    height: 100%;
  
    position: absolute;
    z-index: 1;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    /* GLASS BLUR */
    /* From https://css.glass */
    background: rgba(242, 178, 65, 0.8);;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.3px);
    -webkit-backdrop-filter: blur(6.3px);
}

.item{position:relative;  border-radius: 16px;}
.divHover:hover{
  opacity: 1;
  animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
}

.divHover .name {
    text-align: center;
    color: #ffffff;
    font-size: 36px;
    font-family: "open Sans";
    font-weight: 800;
    margin: 0 auto;
    line-height: 1;
    width: 90%;
}

.divHover .affiliate{
  text-align: center;
  color: #ffffff;
  font-size: 28px;
  font-family: "open Sans";
  font-weight: 700;
  margin: 0 auto;
  display:block;
  line-height: 1;
  margin-top: 10px;
}

.ParentDiv {
    height: fit-content;
    width: 100%;
    position: relative;
    margin-bottom: 1rem;
}

/* .lazy{
  opacity: 0;
} */

#closeMenu{
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

#randomIcon {
  cursor: pointer;
  font-size: min(10vw, .7rem);
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

#creatingMeme, #welcome-overlay{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,0.8);
    z-index: -1;
    opacity: 0;


    margin: 0;
    padding: 0;
    /* background-color: var(--color-bg); */
    color: var(--color-white);
    font-family: 'Hind Siliguri', sans-serif;

    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 500ms ease-in-out;

}


.disabled{
  animation: shake 0.2s;
  border-bottom: 2px solid #f3b342;
}

@keyframes shake {
	 0%, 100% {
		 transform: translateX(5px);
	}
	 10% {
		 transform: translateX(-5px);
	}
	 20% {
		 transform: translateX(5px);
	}
	 30% {
		 transform: translateX(-5px);
	}
	 40% {
		 transform: translateX(5px);
	}
	 50% {
		 transform: translateX(5px);
	}
	 60% {
		 transform: translateX(-5px);
	}
	 70% {
		 transform: translateX(5px);
	}
	 80% {
		 transform: translateX(-5px);
	}
	 90% {
		 transform: translateX(5px);
	}
}


.disabledText{
  animation: shakeText 0.5s;
}

@keyframes shakeText {
  0%, 100% {
      transform: translateX(calc(-50% + 5px));
  }
  10% {
      transform: translateX(calc(-50% - 5px));
  }
  20% {
      transform: translateX(calc(-50% + 5px));
  }
  30% {
      transform: translateX(calc(-50% - 5px));
  }
  40% {
      transform: translateX(calc(-50% + 5px));
  }
  50% {
      transform: translateX(calc(-50% - 5px));
  }
  60% {
      transform: translateX(calc(-50% + 5px));
  }
  70% {
      transform: translateX(calc(-50% - 5px));
  }
  80% {
      transform: translateX(calc(-50% + 5px));
  }
  90% {
      transform: translateX(calc(-50% - 5px));
  }
}



#nothing{
  color: var(--color-orange);
  font-family: 'Hind Siliguri', sans-serif;
  font-size: 18px;
  font-weight: 600;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#meme-generator-container{
  height: 400px;
  display: block;
  position: relative;
  width: 100%;
  /* margin: 0 auto; */
  max-width: 90%;
  /* background: red; */
}

#meme-generator{
    width: 90%;
    border-radius: 3px;
    margin: 0 auto;
    /* height: 100%; */
    /* height: 100%; */
    /* object-fit: contain; */
    position: absolute;
    /* background: blue; */
    /* display: flex; */

    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#meme-generator img{
  width: auto;
  margin: 0 auto;
  max-height: 400px;
  height: auto;
}


.meme--image{
  padding: 0 !important;
}


#main_wrapper{

  margin-top: 20px;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}




  @media (max-width: 576px) { 

    body {
      padding-top: 80px;
    }

    #photos {
      column-count: 2;
    }
    
   .navbar{
    height: 80px;
    position:absolute
   }

    .logo {
        width: 65vw;
        max-width: 320px;
        min-width: 210px;
        height: 60px;
        padding: 0rem 1rem 1rem 1rem;
        text-align: center;
        position: absolute;
        top:0px;
      }
      .logo img {
        width: 13rem;
        max-width: 280px;
      }
      .triangle-left {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 60px 25px 0 0;
        border-color: #ffffff transparent transparent transparent;
      }
      .triangle-right {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 25px 60px 0;
        border-color: transparent #ffffff transparent transparent;
      }

      .mouse {
        bottom: 35px !important;
        left: 35px !important;
      }
   }

   @media (max-width: 1200px) { 
      .divHover .name {
        font-size: 3.6vw;
        line-height: 5vw;
      }
      .divHover .affiliate{
        font-size: 3vw;
      }
      #photos button {
        bottom: 2rem;
      }

      #b{
        width: 250px;
        color: white !important;
        padding: 0.8rem 1.5rem;
      }
   }


@media only screen and (min-width : 1224px) {
  /* Styles */

  /* #photos {
    column-count: 3;
  } */
}



/* Spinner */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}




#scrolling{
  padding-bottom:100px;
  display: none;
}
.mouse {
  display: none;
  position: fixed;
  width: 22px;
  height: 42px;
  left: 50%;
  bottom: 10px;
  margin-left: -12px;
  border-radius: 15px;
  border: 2px solid #193F4C;
  -webkit-animation: intro 1s;
          animation: intro 1s;
}
.scroll {
  display: block;
  width: 3px;
  height: 3px;
  margin: 6px auto;
  border-radius: 4px;
  background: #193F4C;
  -webkit-animation: finger 1s infinite;
          animation: finger 1s infinite;
}
@-webkit-keyframes intro {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes intro {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes finger {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
@keyframes finger {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}



/* Rodri css */


/* General Styles */
* {
  box-sizing: border-box;
}

:root {
  --color-white: rgb(255, 255, 255);
  --color-bg: #ECE0D4;
  --color-dark: #3d8a8a;
  --color-dark-gray: rgb(255, 255, 255, 20%);
  --color-orange: #193f4c;
  /* --main-width: 356px;  */
  --main-width: 456px;
}

.button {
  font-family: 'Hind Siliguri', sans-serif;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  outline: none;
  border: none;
  color: var(--color-white);
  cursor: pointer;
  transition: opacity 250ms ease-in-out;
}

.button:hover {
  opacity: 0.8;
}
input {
  font-family: 'Hind Siliguri', sans-serif;
  color: var(--color-white);
  font-size: 1.15rem;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  outline: none;
  border: none;
  border-bottom: 2px solid var(--color-orange);
  background-color: transparent;
  padding: 0.8125rem 1rem;
  margin-bottom: 1.25rem;
  transition: border-color 250ms ease-in-out;
}
input::placeholder {
  color: white;
}

input:focus {
  border-bottom-color: var(--color-white);
}
/* End General Styles */

.wrapper{
  opacity: 0;
  position: relative;
  width: 100%;
  max-width: var(--main-width);
  background-color: var(--color-dark);
  border-radius: 1.25rem;
  padding: 3.125rem 0;
  text-align: center;
  overflow: hidden;
  /* animation: fadeInContent 1000ms ease-in-out; */
  /* transition: nonr 500ms ease-in-out; */
  /* transition-property: opacity, visibility; */
}

.wrapper-welcome{
  opacity: 1;
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: var(--color-dark);
  height: fit-content;
  border-radius: 1.25rem;
  padding: 3.125rem 0;
  text-align: center; 
}

.wrapper-welcome .stag, .wrapper-welcome .stag2, .wrapper-welcome .stag3{
  opacity: 0;
}

#welcome-overlay #closeMenu{
  opacity: 0;
}

.explore__gallery{
  opacity: 0;
}

h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

/* .wrapper--has-fade {
  opacity: 0;
  visibility: hidden;
} */

@keyframes fadeInContent {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.title-meme {
  font-size: min(10vw, 1.25rem) !important;
  line-height: 1.2;
  font-weight: 600 !important;
  margin-bottom: 1rem !important;
}

.slides-area {
  display: flex;
  transition: margin 500ms ease-in-out;
}

.slides-area__slide {
  width: var(--main-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 0px;
  visibility: hidden;
  transition: height 0.5s ease;
}

.active-slide {
  height: inherit;
  visibility: inherit;
  transition: height 0.5s ease;
}

.slide__image {
  width: 50%;
  scale: 0.8;
  opacity: 0;
}
.meme__image {
  width: 250px;
  height: 250px;
  margin: 0.3125rem;
}

.slide__text {
  padding: 0 2.1875rem;
}

.slide__title {
  font-size: min(10vw, 1.75rem);
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 0.8125rem;
}

.slide__paragraph {
  font-size: 15px;
  font-weight: 100;
  /* margin-top: 0.3125rem; */
  margin: 0.3125rem 0;
}

.slide__footer{
  padding: 0 5rem;
}

.ellipsis span {
  opacity: 0;
  animation: ellipsis 1.4s infinite;
}

.ellipsis span:nth-child(2) {
  animation-delay: 0.2s;
}

.ellipsis span:nth-child(3) {
  animation-delay: 0.4s;
}


@keyframes ellipsis {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes dots {
  0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
          0.25em 0 0 rgba(0,0,0,0),
          0.5em 0 0 rgba(0,0,0,0);
  }
  40% {
      color: white;
      text-shadow:
          0.25em 0 0 rgba(0,0,0,0),
          0.5em 0 0 rgba(0,0,0,0);
  }
  60% {
      text-shadow:
          0.25em 0 0 white,
          0.5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
      text-shadow:
          0.25em 0 0 white,
          0.5em 0 0 white;
  }
}

.button-next {
  background-color: var(--color-orange);
  border-radius: 0.625rem;
  padding: 0.8125rem 2.25rem;
  font-size: 1.15rem;
  margin: 1.25rem 0;
}

.button-next--fade {
  opacity: 0.3;
  pointer-events: none;
  filter: blur(2px);
}

.paginations-area {
  pointer-events: none;
}

.paginations-area__item {
  display: inline-block;
  width: 0.5rem;
  height: 0.375rem;
  background-color: var(--color-dark-gray);
  border-radius: 1.5625rem;
}

.paginations-area__item:not(:last-child) {
  margin-right: 0.25rem;
}

.paginations-area__item--current {
  width: 1.5625rem;
  background-color: var(--color-white);
  animation: activeItem 500ms ease-in-out;
}

@keyframes activeItem {
  from {
    width: 0.5rem;
    background-color: var(--color-dark-gray);
  }

  to {
    width: 1.5625rem;
    background-color: var(--color-white);
  }
}

/* SMARTPHONES */
@media (max-width: 600px) {
  body {
      padding: 0;
  }
  .wrapper {
      max-width: 100%;
      height: 100%;
      border-radius: 0;
      padding: 0;
      margin: 0;
      background-color: rgb(61 138 138 / 90%);
      display: flex;
      flex-direction: column;
      justify-content: center;
  }

  #closeMenu{
    position: absolute;
    top: 60px;
    right: 20px;
    cursor: pointer;
  }

  #main_wrapper{
    margin-top: 120px;
  }

  .slide__footer{
    padding: 0 2rem;
  }

  .wrapper-welcome{
    height: 100%;
  }
}