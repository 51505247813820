
/* MEME */

* {
    box-sizing: border-box;
  }
  
  body {
    font-family: "Karla", sans-serif;
    margin: 0;
    font-size: 16px;
  }
  
  main {
    padding: 36px;
  }
  
  .header {
    display: flex;
    align-items: center;
    height: 65px;
    background: linear-gradient(90deg, #672280 1.18%, #A626D3 100%);
    color: white;
    padding: 20px;
  }
  
  .header--image {
    height: 100%;
    margin-right: 6px;
  }
  
  .header--title {
    font-size: 1.25rem;
    margin-right: auto;
  }
  
  .header--project {
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .form {
    display: grid;
    grid-template: 40px 40px / 1fr 1fr;
    gap: 17px;
    margin-bottom: 17px;
  }
  
  .form--input {
    font-family: "Karla", sans-serif;
    border-radius: 5px;
    border: 1px solid #D5D4D8;
    text-indent: 5px;
  }
  
  .form--button {
    grid-column: 1 / -1;
    font-family: "Karla", sans-serif;
    border-radius: 5px;
    background: linear-gradient(90.41deg, #711F8D 1.14%, #A818DA 100%);
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .meme {
    position: relative;
    height: fit-content;
  }
  
  .meme--image {
    max-width: 100%;
    border-radius: 3px;
  }

  .meme--preview{
    height: 500px !important;
    margin-top: 50px !important;
  }

  /* textarea {
    background: transparent;
    line-height: 1.2em!important;
    overflow: hidden;
    outline: none;
    resize: none !important;
} */


.grow-wrap {
  width: 100%;
  display: grid;
  position: absolute;
  top: 5px;
}


.grow-wrap-b{
  width: 100%;
  display: grid;
  position: absolute;
  bottom: 5px;
}

.grow-wrap::after , .grow-wrap-b::after{
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
  /* Identical styling required!! */
  border: 1px solid black;
  padding: 0.5rem;
  font: inherit;
  /* Place on top of each other */
  grid-area: 1/1/2/2;
}
.grow-wrap > textarea, .grow-wrap-b > textarea {
  background: transparent;
  line-height: 1.2em!important;
  outline: none;
  width: 100%;
  /* You could leave this, 
  but after a user resizes, 
  then it ruins the auto sizing */
  resize: none;
  /* Firefox shows scrollbar on growth, 
  you can hide like this. */
  overflow: hidden;
  /* Identical styling required!! */
  border: 1px solid black;
  padding: 8px;
  font: inherit;
  /* Place on top of each other */
  grid-area: 1/1/2/2;
 
  font-family: impact, sans-serif;
  font-size: 1.2em;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  text-shadow:
    2px 2px 0 #000,
    -2px -2px 0 #000,
    2px -2px 0 #000,
    -2px 2px 0 #000,
    0 2px 0 #000,
    2px 0 0 #000,
    0 -2px 0 #000,
    -2px 0 0 #000,
    2px 2px 5px #000;
  border: none;
  text-align: center;
}

textarea::-webkit-input-placeholder {
  color: white;
}

textarea::placeholder{
  color: white;
}

.grow-wrap-b{
  height: inherit;
}

.grow-wrap-b > textarea {
  position: absolute;
  bottom: 0;
}
  
  .meme--text {
    position: absolute;
    white-space: pre-line;
    width: 80%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    /* padding: 0 5px; */
    font-family: impact, sans-serif;
    font-size: 1.2em;
    text-transform: uppercase;
    color: white;
    letter-spacing: 1px;
    text-shadow:
      2px 2px 0 #000,
      -2px -2px 0 #000,
      2px -2px 0 #000,
      -2px 2px 0 #000,
      0 2px 0 #000,
      2px 0 0 #000,
      0 -2px 0 #000,
      -2px 0 0 #000,
      2px 2px 5px #000;
      border: none;
      text-align: center;
  }
  
  .meme--text::placeholder{
    color: rgb(217, 216, 216);
  }

  .bottom {
    bottom: 10px;
    margin: 0;
  }
  
  .top {
    /* margin: 15px 0 0 0 !important; */
    top: 10px;
    margin: 0;
  }
  